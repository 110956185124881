<template>
    <div class="min-height width-100 padding-90 flex-row justify-content-center align-items-center">
        <img :src="require('../assets/image/paySuccess2.png')" width="50%" fit="contain" />
    </div>
</template>
<script>

export default {
    name: 'Detail',
    components: {},
    data() {
        return {
            list: []
        };
    },
    created() {
    },
    mounted() {},
    methods: {}
};
</script>

<style lang="less" scoped></style>
